.wallet_component{
    height: 100vh;
    width:100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:1.5rem;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    z-index: 100;  
}
 .wallet_component:before{
        content: "";
        position: absolute;
        top:-10px;
        bottom:-10px;
        left:-10px;
        right:-10px;
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
        -moz-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        -webkit-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        backdrop-filter: blur(10px);
        z-index: -1;
    }

.wallet_container{
    width: 100%;
    border:.35rem solid var(--color-secondary);
    border-radius:1rem;
    position: relative;
    overflow: hidden;
    z-index:2;
}
.wallet_container:before{
    content: "";
    position: absolute;
    top:-10px;
    bottom:-10px;
    left:-10px;
    right:-10px;
    background-color: rgba(0, 0, 0, 0.068);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
    -moz-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    backdrop-filter: blur(20px);
    z-index: -1;
}


.wallet_head{
padding:1rem;
border-bottom:.35rem solid var(--color-secondary);
display: flex;
align-items: center;
justify-content: space-between;
z-index:2;
}
.wallet_head h1{
    font-size: 1.5rem;
}
.wallet_head button{
    font-size: 2rem;
    padding:0 1rem;
    border:0;
    color:white;
    background-color: transparent;
    cursor: pointer;
    margin: 0 0 10px 0 !important
}

.wallet_body{
    width: 100%;
    text-align: center;
    border-bottom:.35rem solid var(--color-secondary);
    z-index:2;
}
.wallet_body button{
    display: none;
    cursor: pointer;
}
.chevron{
    display: none;
}
.wallet_body_note{
margin:1rem 0;
}
.wallets{
padding:.5rem;
display: grid;
grid-template-columns: 1fr 1fr;
gap:1.5rem;
place-items: center;
}

.wallet{
    cursor: pointer;
}

.wallet img{
    margin:auto;
    width:4rem;
    height:4rem;
}

.wallet_footer{
    text-align: center;
    padding:1rem;
}
.wallet_footer button{
    border:0;
    margin:.5rem 0;
    padding:.5rem;
    width:90%;
    border-radius: .5rem;
    text-align: center;
    color:var(--color-primary);
    font-weight: var(--font-regular);
    background: var(--color-gradient);
    text-decoration: none;
    cursor: pointer;
}

@media (min-width: 36em){
    
    .wallet_component{
       justify-content: center;
       align-items: center;
       background-color: rgba(0, 0, 0, 0.116);
    }


    .wallet_container{
        max-width: 40rem;
        border-color: #E3001633
    }
    .wallet_container:before{
        content: "";
        position: absolute;
        top:-20px;
        bottom:-20px;
        left:-20px;
        right:-20px;
        background-color: rgba(255, 255, 255, .10);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
        -moz-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        -webkit-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        backdrop-filter: blur(20px);
        z-index: -1;
    }
    
    .wallet_head{
        z-index:2;
        border-bottom:0;
    }
    .wallet_head button i{
        font-size: 2rem;
    }

    .wallet_head h1{
        font-size: 3rem;
       
    }
    .wallet_body{
        width:100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        border-width: .2rem;
        border-color:#FF2936;
    }

    .wallets{
        align-items: center;
    }
    .wallet{
        border:.15rem solid var(--color-secondary);
        border-radius: .5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 17rem;
        height: 4rem;
    }
    .wallet img{
        margin:0 .5rem;
    }
    .wallet_body button{
        display: block;
        margin: 1rem 0;
        width: 15rem;
        padding:.5rem;
        border:0;
        border-radius: .5rem;
        text-align: center;
        color:var(--color-primary);
        font-weight: var(--font-regular);
        background: var(--color-gradient);
        text-decoration: none;
        cursor: pointer;
    }
    .chevron{
        display: block;
        margin-left: auto;
        margin-right: .5rem;
    }
    .chevron i{
        color:white;
    }
    .wallet_footer button{
        max-width:15rem;
    }
}