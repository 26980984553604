.Mainscreen{
    width: 100%;  
    min-height: 100vh; 
    background:url('../../static/phone\ bg\ polygon.png') center center repeat;
    background-size: cover;
    color:var(--color-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo_content{
    display: none;
}

.main_nft_display{
    margin:1rem auto;
    width: 100%;
    max-width: 15rem;
    padding:1rem;
    background-color: rgba(0, 0, 0, 0);
}
.main_nft_display::before{
    inset: 0;
    position: absolute;
    content: "";
    background-color: rgba(255, 15, 15, 0);
    filter: blur(2px);
    z-index: -1;
}

.main_nft_display video{
    height: 100%;
    width: 100%;
    object-fit:cover;
}

.Mainscreen .content_container .main_content{
    padding:.5rem;
    width: 90%;
    margin: 1rem;
}


.Mainscreen .main_content span{
    line-height: 2rem;
    font-size: 2.25rem;
    font-weight: var(--font-bold);
}
.Mainscreen .main_content p{
    margin-top:1rem;
    font-size: 1.25rem;
}

.btn_container{
    z-index: 2;
}

.Mainscreen .main_content button{
    padding:.5rem;
    width:70%;
    max-width: 35rem;
    cursor: pointer;
    font-size: 1.25rem;
    border-radius: .5rem;
    color:white;
    outline:none;
    background:linear-gradient(93.15deg, #9281F5 22.23%, #714BD0 71.23%);
    border:2px solid white;

}

@media (min-width:60em) {
    
     .Mainscreen{
       background: url('../../static/Main\ Screen\ FreemintBG\ 1.svg')center center no-repeat;
       background-size: cover;
    }
    .content_container{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: calc(100vh - 6rem);
    }
    .main_content{
        width: 50%;
        max-width: 44rem;
    }
    .Mainscreen .main_content span{
        background: var(--polygon-color-gradient1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 4.32rem;
        line-height: 5.125rem;
        display: block;
        max-width: 44rem;
    }
    
     .logo_content{
         display: block;
     }

     .main_content img, .main_content span, .main_content p, .main_content .btn_container{
         margin: 2rem 0; 
     }
     .Mainscreen .main_content p{
         font-size: 2rem;
     }
     .main_nft_display{
         width: 30%;
         max-width:28rem;
     }
    
     .main_nft_display{
        padding:0;
    }
    .claim_nft_btn{
        font-size: 2rem;
    }

}

