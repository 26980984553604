.success_popup_container{
    height: 100vh;
    width:100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:1.5rem;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    z-index: 100;
}

.success_popup_container:before{
    content: "";
    position: absolute;
    top:-5px;
    bottom:-5px;
    left:-5px;
    right:-5px;
    background-color: rgba(0, 0, 0, 0.068);
    box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .2); 
    -moz-box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .2);
    backdrop-filter: blur(10px);
    z-index: -1;
}

.popup_body{
    border:1px solid white;
    text-align: center;
    width:20rem;
    max-width:22rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding:1.5rem;
    border-radius: 1rem;
    border:.35rem solid var(--color-secondary);
}
.popup_body:before{
    content: "";
    position: absolute;
    top:-5px;
    bottom:-5px;
    left:-5px;
    right:-5px;
    background-color: rgba(0, 0, 0, 0.068);
    box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .2); 
    -moz-box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .2);
    backdrop-filter: blur(10px);
    z-index: -1;
}

.popup_body .popup_text{
    line-height: 1;
}

.popup_body .popup_btn{
    margin-top:1rem;
    border:0;
    padding:.5rem;
    width:5rem;
    max-width: 5rem;
    border-radius: .5rem;
    text-align: center;
    color:var(--color-primary);
    font-weight: var(--font-regular);
    background: var(--color-gradient);
    text-decoration: none;
    cursor: pointer;
}
@media (min-width: 36em){
    
    .success_popup_container{
       justify-content: center;
       align-items: center;
       background-color: rgba(0, 0, 0, 0.116);
    }
    .success_popup_container:before{
        content: "";
        position: absolute;
        top:-20px;
        bottom:-20px;
        left:-20px;
        right:-20px;
        background-color: rgba(255, 255, 255, .10);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
        -moz-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        -webkit-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        backdrop-filter: blur(20px);
        z-index: -1;
    }

}