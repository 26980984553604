.Mainscreen{
    width: 100%;  
    min-height: 100vh; 
    background:url('../../static/mobBG2.svg') center center repeat;
    background-size: cover;
    color:var(--color-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo_content{
    display: none;
}

input[type=tel] {
    width: 50px !important;
    height: 50px !important
}

.error{
    color: unset !important;
    color: white !important;
    display: flex;
    flex-wrap: nowrap;
    padding:0rem !important;
}

.main_nft_display{
    margin:1rem auto;
    width: 95%;
    padding:1rem;
    background-color: rgba(0, 0, 0, 0);
}


.main_nft_display video{
    height: 100%;
    width: 100%;
    object-fit:cover;
}

.Mainscreen .content_container .main_content{
    padding:.5rem;
    width: 90%;
    margin:1rem auto;
}


.Mainscreen .main_content h1{
    background: var(--color-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 2rem;
    font-size: 2.25rem;
    font-weight: var(--font-bold);
}
.Mainscreen .main_content p{
    margin-top:1rem;
    font-size: 1.25rem;
}

.inputFl_container{
    margin-top:1rem;
    display: grid;
}

.inputFl_container input{
padding:1rem;
outline:none;
display: block;
border-radius: .5rem;
color: var(--color-primary);
font-weight: var(--font-bold);
background: var(--color-gradient);
position: relative;
border:0;
background: rgba(255, 255, 255, 0.096);
border:2px solid var(--color-secondary);
}


.inputFl_container button{
    width:50%;
    margin-top:.5rem;
    padding:.5rem;
    font-weight: var(--font-bold);
    color:var(--color-primary);
    outline:none;
    background: linear-gradient(93.15deg, #E11D17 22.23%, rgba(255, 77, 119, 0.76) 71.23%);
    border:2px solid white;
    cursor: pointer;
    z-index: 10;
    border-radius: .5rem;
    margin: 10px 0 !important;
}

.otp_blocks_container{
    max-width:20rem;
    margin:1rem 0;
    display: grid;
    place-items: center;
    gap:.5rem;
    grid-template-columns: repeat(4, 1fr);
}
.otp_blocks_container input{
    border:2px solid var(--color-secondary);
    font-size: 1.25rem;
    text-align: center;
    font-weight: var(--font-bold);
}
.otp_blocks_container  button{
    margin-top:.5rem;
    margin-left:.5rem;
    width: 100%;
    padding:.5rem;
    border-radius: 0.5rem;
    grid-column:1/3;
    font-weight: var(--font-bold);
    color:var(--color-primary);
    outline:none;
    background: linear-gradient(93.15deg, #E11D17 22.23%, rgba(255, 77, 119, 0.76) 71.23%);
    border:2px solid white;
    cursor: pointer;
    z-index: 10;
}

.otp_digit{
    width: 4rem;
    height: 4rem;
    border-radius:.5rem;

}
@media (min-width:60em) {
    
     .Mainscreen{
       background: url('../../static/Main-Screen-FreemintBG.svg')center center no-repeat;
       background-size: cover;
    }
    .Mainscreen .main_content h1{
        font-size: 3rem;
        line-height: 3rem;
    }
    
     .logo_content{
         display: block;
     }
     .main_content h1, .main_content p{
         margin: 2rem 0; 
     }
     .inputFl_container{
         width:70%
     }
     .main_nft_display{
         width: 85%;
     }
     .content_container{
         display: flex;
         flex-direction: row-reverse;
         width: 85%;
     }
     .main_nft_display{
        padding:0;
    }

}

