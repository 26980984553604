.how2connect{
    width:100%;
    height: 100vh;
    flex-direction: column;
    background: url('../../static/mobBG2.svg') center center no-repeat;
    background-size: cover;
    position: relative;
}

.how2connect_container{
    transform: translateY(30px);
    display: flex;
    flex-direction: column;
}


.how2connect_container .heading{
    margin:1rem 2rem;
    max-width: 15rem;
    font-size: 2rem;
    text-align: justify;
    font-weight: 700;
    color:  #FF2936;
}

.imp_link{
    background: var(--color-gradient);
    color:white;   
    font-style: italic;  
    text-decoration: underline;
    cursor: pointer;
}

.step{
    margin:1rem;
}


.how2Connect_footer{
    text-align: center;
}
.how2Connect_footer .final_note{
margin:1rem 2rem;
text-align: initial;
}

.go_back_btn{
    margin: auto;
    display: block;
    background-color: transparent;
    padding:0;
    border:0;
    text-align: center;
    padding:.5rem;
}
.go_back_btn i{
    color:wheat;
    font-size: 3rem;
    cursor: pointer;
}
.floating_gbk_btn {   
    animation-name: floating_btn_x_axis; 
    animation-duration: 5s; 
    animation-iteration-count: infinite; 
    animation-timing-function: ease-in-out; 
} 
  
@keyframes floating_btn_x_axis { 
    0% { transform: scale(1); } 
    50%  { transform: scale(1.1); } 
    100%   { transform: scale(1); }     
} 

.how2Connect_footer .connect_wallet{
    padding:.5rem;
    width:65%;
    max-width: 20rem;
    border:0;
    border-radius: 1rem;
    color:white;
    outline:none;
    background: var(--color-gradient);
    cursor: pointer;
}

@media (min-width:40em) {
    .how2connect{
        background: url('../../static/Thank\ you\ and\ Preview\ NFTBG.svg') center center no-repeat;
        background-size: cover;
    }
    .how2connect_container .heading{
        text-align: center;
        font-size: 2.5rem;
        max-width: 20rem;
        padding:1rem;
        line-height: 1;
        margin:0;
    }
    .how2connect_container{
        height: calc(100vh - 15rem);
        justify-content: center;
        align-items: center;
        font-size: 1.15rem;
    }
    .how2connect_container .steps{
        max-width: 90%;
        margin: auto;
    }

    .how2Connect_footer{
        max-width: 80%;
        text-align: center;
    }
    .how2Connect_footer .final_note{
        text-align: center;
    }
    .how2Connect_body{
        max-width: 25rem;
       align-self: center;
    }
}