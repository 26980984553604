/* GLOBAL STYLE PRESETS */
:root{
  --color-primary: #FFFFFF;
  --color-secondary: #FF5560;
  --color-gradient-2:linear-gradient(180deg, #E30016 0%, rgba(217, 74, 88, 0.52) 100%);
  --color-gradient:linear-gradient(93.15deg, #E11D17 22.23%, rgba(255, 77, 119, 0.76) 71.23%);
  --color-black: #1A1A1A;
  --font-regular:400;
  --font-bold:700;
  --font-hippo:900;

  --polygon-color-gradient1:linear-gradient(92.8deg, #714BD0 0%, #9281F5 117.12%);

}

/* FONTS */

/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap'); */
@font-face {
  font-family: "Caustenround";
  src: url(../src/static/fonts/CaustenRound-Black.f3236fdf.woff) format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Caustenround";
  src: url(../src/static/fonts/CaustenRound-Regular.8aea0fac.woff) format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Caustenround";
  src: url(../src/static/fonts/CaustenRound-SemiBold.943ece75.woff) format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
a{
  text-decoration: none;
  color:var(--color-primary);
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: Caustenround, sans-serif;
  background-color: black;
  color:white;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


/*SITE STYLES*/
.shadow {
  -moz-box-shadow: 0px -5px 20px 5px rgba(255, 0, 0, 0.657);
  -webkit-box-shadow: 0px -5px 20px 5px rgba(255, 0, 0, 0.657);
  box-shadow: 0px -5px 20px 5px rgba(255, 0, 0, 0.657);
}
