.how2connect{
    width:100%;
    min-height: 100vh;
    flex-direction: column;
    background: url('../../static/phone\ bg\ polygon.png') center center no-repeat;
    background-size: cover;
    position: relative;
}

.how2connect_container{ 
    display: flex;
    flex-direction: column;
    margin-top:1.5rem;
}

.how2connect_container .heading{
    margin:1rem 2rem;
    max-width: 15rem;
    font-size: 2rem;
    text-align: justify;
    font-weight: 700;
    background: linear-gradient(180deg, #714BD0 -59.48%, #9281F5 150.86%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.imp_link{
    background: var(--polygon-color-gradient1);
    color:white;   
    font-style: italic;  
    text-decoration: underline;
    cursor: pointer;
}

.steps{
    font-size: 1.15rem;
}
.step{
    display: flex;
    margin:1rem;
}
.step span{
    margin:0 .25rem;
}


.how2Connect_footer{
    padding:.5rem;
    font-size: 1.15rem;
    text-align: center;
}
.how2Connect_footer .final_note{
    margin:.5rem;
}

.go_back_btn{
    margin: auto;
    display: block;
    border:0;
    text-align: center;
    background-color: transparent;
}
.go_back_btn i{
    background: linear-gradient(180deg, #714BD0 -59.48%, #9281F5 150.86%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5rem;
    cursor: pointer;
    border:2px solid white;
    border-radius: 50%;
}

.floating_gbk_btn {   
    animation-name: floating_btn_x_axis; 
    animation-duration: 1s; 
    animation-iteration-count: infinite; 
    animation-timing-function: ease-in-out; 
} 
  
@keyframes floating_btn_x_axis { 
    0% { transform: translateX(1rem); } 
    50%  { transform: translateX(0.9rem); } 
    100%   { transform: translateX(1rem); }     
} 

.how2Connect_footer .connect_wallet{
    padding:.5rem;
    width:65%;
    max-width: 20rem;
    border:0;
    border-radius: 1rem;
    color:white;
    outline:none;
    background: var(--polygon-color-gradient1);
    cursor: pointer;
    border: 2px solid white;
}

@media (min-width:40em) {
    .how2connect{
        background: url('../../static/Thank\ you\ and\ Preview\ NFTBG\ 1.svg') center center no-repeat;
        min-height: 100vh;
        background-size: cover;
    }
    .how2connect_container .heading{
        text-align: center;
        font-size: 2.5rem;
        max-width: 20rem;
        padding:1rem;
        line-height: 1;
        margin:0;
    }
    .how2connect_container{
        height: calc(100vh - 15rem);
        justify-content: center;
        align-items: center;
        font-size: 1.15rem;
    }
    .how2connect_container .steps{
        max-width: 90%;
        margin: auto;
    }
    .how2connect_container .step{
        text-align: center;
        justify-content: center;
    }
    .how2connect_container .step p{
        max-width:35rem;
    }

    .how2Connect_footer{
        max-width: 80%;
        text-align: center;
    }
    .how2Connect_footer .final_note{
        text-align: center;
    }
    .how2Connect_body{
        max-width: 25rem;
       align-self: center;
    }
}