.project_details{
    font-size: 1rem;
    padding:.5rem;
    max-width: 50rem;
}
.project_details .head .btn_container{
    font-size: 1.2rem;
    margin: .5rem 1.25rem;
    cursor: pointer;
}
.project_details .head .btn_container a:hover{
    color: #9281F5;
}
.project_details .head .btn_container i{
    margin-right:.5rem;
}

.project_details .head .head_nav{
    margin: .5rem 1.5rem;
}
.project_details .head .head_nav a{
    margin-right:.5rem;
    cursor: pointer;
}

.project_details .body{
    padding:1rem;
    margin: .5rem 1.5rem;
    border-radius: .5rem;
    background: rgba(93, 93, 93, 0.39);
    mix-blend-mode: normal;
    font-size: 1rem;
}
.project_details .body .heading{
    font-size: 1.5rem;
}

.project_details .body .sub_heading{
    display: block;
    margin-top:.5rem;
    margin-bottom: 1rem;
};

.project_details .body .body_text{
    text-align: justify;
}

.project_details .footer{
    display: grid;
    padding:1rem;
    margin: .5rem 1.5rem;
    border-radius: .5rem;
    background: rgba(93, 93, 93, 0.39);
    grid-template-columns: 1fr 1fr;
    gap:1rem;
    place-items: start;
}


.project_details .footer .detail_body img{
    width:1.8rem;
    border:1px solid white;
    border-radius: 50%;
    margin-right: .5rem;
}

.project_details .footer .detail_body{
    margin-top:.5rem;
    display: flex;
    align-items: center;
}


@media(min-width:50em){

    .project_details{
        font-size: 1.1rem;
    }

    .project_details .body{
    font-size: 1.1rem;
    }
    
    .project_details .footer .detail_body img{
        width:2rem;
    }
}

@media (min-width:62em){
.project_details{
    height: 100%;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items:start;
}

.project_details .head .btn_container{
    font-size: 1.8rem;
}

.project_details .body{
    background: initial;
    font-size: 1.25rem;
}
.head_nav{
    display: none;
}
.project_details .body .heading{
    font-size: 2.5rem;
}

.project_details .body .sub_heading{
    display: block;
    margin-top:.5rem;
    margin-bottom: 1rem;
};

.project_details .body .body_text{
    text-align: justify;
}

.project_details .footer .detail_body img{
    margin-right: 1rem;
}
}
