.share_nft_container{
    display: flex;
    width:100%;
    min-height: 100vh;
    flex-direction: column;
    background: url('../../static/phone\ bg\ polygon.png') center center no-repeat;
    background-size: cover;
}

.share_nft{
    margin-top:2rem;
    padding:.5rem;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.share_nft_container .heading{
    font-size: 2rem;
    text-align: center;
    font-weight: var(--font-bold);
}

.share_nft_vid_container{
    margin:1rem auto;
    max-width: 14rem;
    height: 22rem;
    background-color: rgba(0, 0, 0, 0);
    border:2px solid #714BD0;
    border-radius: 1.5rem;
}

.share_nft_body{
    width:100%;
    text-align: center;
}

.share_nft_body button{
    padding:.5rem;
    width:10rem;
    border:0;
    border-radius: .5rem;
    color:white;
    outline:none;
    font-weight: bold;
    background: var(--polygon-color-gradient1);
    cursor: pointer;
}
.share_nft_vid_container video{
    border-radius: 1.5rem;
    height: 100%;
    width: 100%;
    object-fit:cover;
}

.share_nft_footer{
    transform: translateY(-5px);
    text-align: center;
    margin:.5rem 0;
}
.share_nft_footer .social_meadia_handles{
    display: flex;
    justify-content: center;
}
.social i{
    font-size:1.8rem;
    margin:0 .5rem;
}

@media (min-width:40em) {
    .share_nft_container{
        background: url('../../static/Thank\ you\ and\ Preview\ NFTBG\ 1.svg') center center no-repeat;
        background-size: cover;
    }
}