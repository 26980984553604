/* F-E STYLs */
:root{
  --color-gradien1: linear-gradient(93.15deg, #9281F5 22.23%, #714BD0 71.23%);
  --color-gradient2: linear-gradient(93.15deg, #714BD0 22.23%, #9281F5 71.23%);
  --color-hover: #9d7af8;
}
.phygBody{
  background-color: #0d0e1d;
  font-family: "Caustenround", sans-serif;
  line-height: 1.5;
  color: #f4f4f4;
  min-height: 100vh;
  width: 100%;
  background-image: url('../../components/PhygyFE/static/Microsite\ polygon\ bg.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}
img{
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: #f4f4f4;
}
button {
  border: 0;
  outline: 0;
  padding: 12px 40px;
  border-radius: 41px;
  margin: 15px auto;
  cursor: pointer;
  border: 2px solid white;
}
.primary {
  background:var(--color-gradient2); 
  color: white;
  font-weight: 600;
  font-size: 20px;
  transition: .3s ease-in-out;
  transition: box-shadow 0.25s ease-in-out;
}
.primary:hover{
  box-shadow: 0 0 10px var(--color-hover);
}

.microsite {
  height: 100%;
  width: 100%
}
.microsite .microsite_page {
  height: 100%;
  width: 100%;
  display: flex;
}

/* NAV STYLES */
.microsite .microsite_nav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 30px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.microsite .microsite_nav .logo {
  z-index: 5;
}
.microsite .right_nav {
  padding: 20px 25px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
}
.microsite .linkbar {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  background: rgba(26, 26, 26, 0.883);
  background-blend-mode: multiply;
  border-radius: 60px;
  position: relative;
  box-shadow: 0 0 10px #79787885;
  padding: 12px 16px;
}
.microsite .linkbar .linkbar_text {
  width: 220px;
  overflow: auto;
  margin-right: 15px;
  margin-left: 15px;
}
.microsite .linkbar .linkbar_text::-webkit-scrollbar {
  display: none;
}

.microsite .mobile_copy {
  z-index: 5;
  background: var(--color-gradien1);
  padding: .5rem;
  place-items: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  font-size: 2rem;
  display: none;
  color:white;
  border:1px solid white;
  cursor: pointer;
}
.microsite .copytext_mobile {
  display: none;
}
.microsite .linkbar button.button {
  z-index: 10;
  display: flex;
  align-items: center;
  margin: 0;
}
.microsite .linkbar button.button i {
  margin-right: 8px;
  font-size: 1.25rem;
}


/* MAIN PANEL SYLES */
/* UTILITY STYLES*/
.microsite .utilities {
  display: -ms-grid;
  display: grid;
  align-items: center;
  justify-items: center;
  min-height: 100vh;  
  z-index: 10;
  background: url('../../components/PhygyFE/static/Vector.svg') no-repeat fixed 95% 95%;

}
.microsite .utilities .utilities_container {
  width: 80%;
  max-width: 50rem;
  margin-top:50px;
}
.microsite .utilities .utilities_container .utility {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, .45);
  padding: 8px;
  margin: 14px auto;
  border-radius: 24px;
  cursor: pointer;
  transition: transform 0.25s ease-in-out, background-color 0.2s ease-in-out;
  box-shadow: 0 0 10px #79787885;
  outline: none;
}
.microsite .utilities .utilities_container .utility:hover{
  -ms-transform: scale(1.02); /* IE 9 */
  -webkit-transform: scale(1.02); /* Safari 3-8 */
  transform: scale(1.02); 
  background: rgb(0, 0, 0);
}


.microsite .utilities .utilities_container .utility .utility_icon {
  margin-right: 15px;
  background: rgba(40,40,40,.5);
  border-radius: 16px;
  height: 80px;
  width: 80px;
  padding: 12px;
  display: -ms-grid;
  display: grid;
  place-items: center;
}


.microsite .utilities .utilities_container .utility .utility_icon img {
  height: 50px;
}
.microsite .utilities .utilities_container .utility .utility_text {
  margin: 10px 0;
}
.microsite .utilities .utilities_container .utility .utility_text .utility_heading {
  color:rgb(255, 255, 255);
  font-size: 30px;
  font-weight: 900;
} 
.microsite .utilities .utilities_container .utility .utility_text .utility_subheading {
  font-size: 20px;
  font-weight: 700px;
}

/*NFT DISPLAY PANNEL STYLES*/
.microsite_page .nftdisplay {
  width: 35%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: -ms-grid;
  display: grid;
  place-items: center;
  box-shadow: 0 0 1.5rem 0 rgba(255, 255, 255, 0.2);
  padding-top: 1rem;
  outline:none;
  border:1px solid rgba(228, 228, 228, 0); /*this necesary! otherwise scroll will break*/
}
.microsite .microsite_page .nftdisplay .nftdisplay_container {
  text-align: center;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .nftdisplay_nft {
  margin: 20px auto;  
  /* height: 432px; */
  width: 240px;
  overflow: hidden;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .nftdisplay_nft video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .projectname {
  font-size: 30px;
  font-weight: 900;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .nftname {
  font-weight: 900;
  font-size: 40px;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .nftnumber {
  font-weight: 600;
  font-size: 32px;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .aboutbutton a {
  color: #f4f4f4;
  font-weight: 600;
  font-size: 20px;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .aboutbutton button {
  background: var(--color-gradien1);
  display: flex;
  align-items: center;
  box-shadow: 0 0 20px #714BD0;
  transition: .3s ease-in-out;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .aboutbutton button:hover{
  box-shadow: 0 0 20px #9d7af8;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .aboutbutton button img {
  margin-right: 5px;
  height: 24px;
}

.microsite .microsite_page .mainpanel {
  flex: 65% 1;
  height: 100vh;
}

/* RESPONSIVE STYLES */
/* MAX WIDTH 991px*/
@media screen and (max-width:991px) {
  
  .microsite .linkbar {
  display: none;
}
.microsite .mobile_copy {
  display: -ms-grid;
  display: grid;
}
.microsite .copytext_mobile {
  position: fixed;
  font-size: 1.2rem;
  font-weight: bold;
  bottom: 40px;
  background: var(--color-gradien1);
  padding: 8px 15px;
  border-radius: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.microsite .microsite_page {
  display: flex;
  flex-direction: column;
}

.microsite .microsite_page .nftdisplay {
  padding:.5rem;
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: .5rem;
  margin-top: 100px;
  box-shadow: none;
  background-color: #0d0e1d00;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container {
  padding: 0;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .nftdisplay_nft {
   width: 310px; 
}
.microsite .microsite_page .mainpanel {
  flex: 100% 1;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.microsite .utilities {
  min-height: auto;
 background:none;

}
.microsite .utilities .utilities_container {
  width: 80%;
  margin: 30px auto;
}
.microsite .utilities .utilities_container .utility {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, .45);
  padding: 10px;
  margin: 15px auto;
  border-radius: 24px;
  cursor: pointer;
}
.microsite .utilities .utilities_container .utility .utility_text {
  margin: 10px 0;
}

}

/* MAX WIDTH 768*/

@media screen and (max-width:768px) {
  .phygBody{
    background-image: url('../../components/PhygyFE/static/phone\ bg\ polygon.png');
  }

  .microsite .microsite_page {
  height: 100%;
  width: 100%;
  display: flex;
}


.microsite .microsite_page .nftdisplay .nftdisplay_container .projectname {
  font-size: 28px;
}
.microsite .microsite_page .nftdisplay {
  width: 90%;
}

.microsite .microsite_page .nftdisplay .nftdisplay_container .nftname {
  font-size: 32px;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .nftnumber {
  font-size: 24px;
}
.microsite .microsite_page .nftdisplay .nftdisplay_container .nftdisplay_nft {
  width: 180px;
}
.microsite .utilities .utilities_container {
  width: 95%}
.microsite .utilities .utilities_container .utility {
  padding: 10px;
  width: auto;
  max-width: 92%}
.microsite .utilities .utilities_container .utility .utility_text .utility_heading {
  font-size: 22px;
}
.microsite .utilities .utilities_container .utility .utility_text .utility_subheading {
  display: none;
}
}

