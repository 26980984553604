.navbar{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:2rem;
    height: 5rem; 
}

.logo:nth-child(2){
    width:4rem;
    margin-top:.5rem;
}

.logo{
    max-width:8rem;
}

@media (min-width:60em){
    .navbar{
        justify-content: start;
        padding:1rem;
     }
     .logo{
        max-width:10rem;
        margin:.5rem;
    }
    
     .logo:nth-child(2){
         display: none;
     }
}
