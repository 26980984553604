.connect_wallet_btn{
    border:0;
    margin:.5rem 0;
    padding:.5rem;
    width:40%;
    max-height: 17.87rem;
    border-radius: .5rem;
    text-align: center;
    color:var(--color-primary);
    font-weight: var(--font-regular);
    background: var(--polygon-color-gradient1);
    text-decoration: none;
    cursor: pointer;
    border:2px solid white;
    font-size: 1.25rem;
}
.wallet_btn_container{
    background-color: rgba(0, 0, 0, .45);
    display: flex;
    justify-content: space-between;
    padding:1rem;
    align-items: center;
    border-radius: 1rem;
    margin:1rem 0;
    box-shadow: 0 0 10px #79787885;
    cursor: pointer;
    transition: transform 0.25s ease-in-out, background-color 0.2s ease-in-out;
}
.wallet_btn_container span{
    font-size: 1.8rem;
}
.wallet_btn_container:hover{
    -ms-transform: scale(1.02); /* IE 9 */
    -webkit-transform: scale(1.02); /* Safari 3-8 */
    transform: scale(1.02); 
    background: rgb(0, 0, 0);
}
/* UTILITY STYLES*/
.utilities {
    display: -ms-grid;
    display: grid;
    align-items: start;
    min-height: 100vh;  
    z-index: 10;
    background: url('../../../PhygyFE/static/Vector.svg') no-repeat fixed 95% 95%;
  
  }
  .utilities .utilities_container {
    width: 80%;
    max-width: 50rem;
    margin:12rem 3.5rem;
  }
  .utilities .utilities_container .utility {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, .45);
    padding: 8px;
    margin: 14px auto;
    border-radius: 24px;
    cursor: pointer;
    transition: transform 0.25s ease-in-out, background-color 0.2s ease-in-out;
    box-shadow: 0 0 10px #79787885;
    outline: none;
  }
  .utilities .utilities_container .utility:hover{
    -ms-transform: scale(1.02); /* IE 9 */
    -webkit-transform: scale(1.02); /* Safari 3-8 */
    transform: scale(1.02); 
    background: rgb(0, 0, 0);
  }
  
  
  .utilities .utilities_container .utility .utility_icon {
    margin-right: 15px;
    background: rgba(40,40,40,.5);
    border-radius: 16px;
    height: 80px;
    width: 80px;
    padding: 12px;
    display: -ms-grid;
    display: grid;
    place-items: center;
  }
  
  
  .utilities .utilities_container .utility .utility_icon img {
    height: 50px;
  }
  .utilities .utilities_container .utility .utility_text {
    margin: 10px 0;
  }
  .utilities .utilities_container .utility .utility_text .utility_heading {
    color:rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 900;
  } 
  .utilities .utilities_container .utility .utility_text .utility_subheading {
    font-size: 20px;
    font-weight: 700px;
  }
  

  /* MAX WIDTH 991px*/
@media screen and (max-width:991px) {
     .utilities {
        min-height: auto;
        background:none;
      }
       .utilities .utilities_container {
        width: 80%;
        margin: 30px auto;
      }
       .utilities .utilities_container .utility {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, .45);
        padding: 10px;
        margin: 15px auto;
        border-radius: 24px;
        cursor: pointer;
      }
       .utilities .utilities_container .utility .utility_text {
        margin: 10px 0;
      }
}

@media screen and (max-width:768px) {
    .wallet_btn_container{
        margin:auto;
        width:92.5%;
    }
    .wallet_btn_container span{
        font-size: 1.25rem;
    }
    .connect_wallet_btn{
       width: 50%;
        font-size: 1.15rem;
    }
     .utilities .utilities_container {
        width: 95%}
       .utilities .utilities_container .utility {
        padding: 10px;
        width: auto;
        max-width: 92%
    }
       .utilities .utilities_container .utility .utility_text .utility_heading {
        font-size: 22px;
      }
       .utilities .utilities_container .utility .utility_text .utility_subheading {
        display: none;
      }
}
